<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: 'dark:bg-gray-950',
  },
})
</script>

<template>
  <div>
    <div class="h-screen flex-col flex items-center -mt-10 justify-center">
      <img src="/assets/images/logo.svg" class="mb-8" />
      <div class="relative z-10">
        <slot />
      </div>
      <div class="absolute w-full h-full right-0">
        <img class="w-full h-full" src="/assets/images/auth-overlay.svg" />
      </div>
    </div>
  </div>
</template>
